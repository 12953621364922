import React from "react";
import Content from "../Content";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const AboutPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="about">
      <section className="hero is-primary is-bold is-medium">
        <div className="hero-body about-hero">
          <div className="container">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="section">
                  <h1 className="title">
                    <span className="title__about-us">
                      <FormattedMessage id="about_us" />
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="section">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

export default AboutPageTemplate;
