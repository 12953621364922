import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { HTMLContent } from "../components/Content";
import AboutPageTemplate from "../components/AboutPageTemplate";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const AboutPage = ({ data, path }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout path={path}>
      <SEO
        title={post.frontmatter.meta_title}
        description={post.frontmatter.meta_description}
      />
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  path: PropTypes.string,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta_title
        meta_description
      }
    }
  }
`;
